import { Grid, Tooltip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div className='bg-neutral-50 border border-neutral-200'>
      <div className='p-16 max-sm:p-8'>
        <Grid container spacing={2} direction={{ xs: 'column', lg: 'row' }}>
          <Grid item xs={4} textAlign={'left'}>
            <div>
              <div className='flex items-center'>
                <Link to={'/privacy'} className='font-bold'>
                  개인정보처리방침
                </Link>
                <span className='text-sm text-neutral-500 mx-2'>|</span>
                <Link to={'/agreement'}>이용약관</Link>
              </div>
              <div className='text-sm text-neutral-500 py-2 max-sm:text-xs'>
                <p>
                  회사명 | <span className='text-neutral-900'>와우</span>
                </p>
                <p>
                  대표 | <span className='text-neutral-900'>김선우</span>
                </p>
                <p className='break-keep'>
                  사업자번호 | <span className='text-neutral-900'>322-58-00920</span>
                </p>
                <p className='break-keep'>
                  주소 | <span className='text-neutral-900'>경기도 파주시 1218, 5층 518호</span>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} textAlign={{ xs: 'left', lg: 'center' }}>
            <h3 className='text-xl font-bold max-sm:text-lg'>고객센터</h3>
            <div className='py-2'>
              <p className='text-sm text-amber-500 font-bold'>365일 24시간</p>
              <p className='text-neutral-900 font-bold text-lg'>
                <Tooltip title='눌러서 바로 상담하기' arrow>
                  <a href='tel:010-7764-2887'>010-7764-2887</a>
                </Tooltip>
              </p>
            </div>
            <div className='py-2 flex justify-center'>
              <a href='https://blog.naver.com/wowstore_' target='_blank' rel='noreferrer'>
                <img src={'/blog.png'} alt='네이버 블로그' className='h-8 w-8' />
              </a>
            </div>
          </Grid>
          {/* <Grid item xs={4} textAlign={{ xs: 'center', lg: 'right' }}>
            <a
              href='https://www.ictmarket.or.kr:8443/ajax/ajax3.do?id=PRE0000172760&seq=1&yn=1'
              target='_blank'
              rel='noreferrer'
              className='inline-block'
            >
              <img className='cursor-pointer h-32' alt='사전승낙서' src='/cert_icon.png' />
            </a>
          </Grid> */}
        </Grid>
      </div>
      <div className='text-center pb-8'>
        <p className='text-xs text-neutral-500'>@{new Date().getFullYear()} WowStore All Rights Reserved</p>
      </div>
    </div>
  );
};
